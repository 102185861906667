import React from 'react';
import { Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import imageDai from './image/Dai.webp';
import imageDaoAnhPhi from './image/DaoAnhPhi.webp';
import imageHiep from './image/Hiep.webp';
import imageLyManhHung from './image/LyManhHung.webp';
import imageNghiemGiaHy from './image/NghiemGiaHy.webp';
import imageNguyenNgocThanh from './image/NguyenNgocThanh.webp';
import imageNguyenVanDuy from './image/NguyenVanDuy.webp';
import imageThanh from './image/Thanh.webp';
import imageTranQuocDat from './image/TranQuocDat.webp';
import './CommentPerson.css';
import { requestFullscreen } from '../../commonFunction/fullscreenUtils';

function CommentPerson() {
  const navigate = useNavigate();
  const location = useLocation();

  const selectedPerson = location.state?.selectedPerson;
  const nameCity = location.state?.nameCity;

  const handleBack = () => {
    requestFullscreen();
    navigate('/mux/choose-person', { state: { nameCity: nameCity } });
  };

  const handleHome = () => {
    requestFullscreen();
    navigate('/mux');
  };

  return (
    <div className="choose-background">
      <Button 
        variant="contained" 
        color="primary" 
        className="mui-button"
        disabled
      >
        {nameCity}
      </Button>
      <Box className="canvas-container">
        {selectedPerson?.ten === "Anh Đại" ? (
          <img className="person-image" src={imageDai} alt="Anh Đại" />
        ) : selectedPerson?.ten === "Anh Đào Ánh Phi" ? (
          <img className="person-image" src={imageDaoAnhPhi} alt="Anh Đào Ánh Phi" />
        ) : selectedPerson?.ten === "Anh Hiệp" ? (
          <img className="person-image" src={imageHiep} alt="Anh Hiệp" />
        ) : selectedPerson?.ten === "Anh Lý Mạnh Hùng" ? (
          <img className="person-image" src={imageLyManhHung} alt="Anh Lý Mạnh Hùng" />
        ) : selectedPerson?.ten === "Anh Nghiêm Gia Hỷ" ? (
          <img className="person-image" src={imageNghiemGiaHy} alt="Nghiêm Gia Hỷ" />
        ) : selectedPerson?.ten === "Anh Nguyễn Ngọc Thạnh" ? (
          <img className="person-image" src={imageNguyenNgocThanh} alt="Anh Nguyễn Ngọc Thạnh" />
        ) : selectedPerson?.ten === "Anh Nguyễn Văn Duy" ? (
          <img className="person-image" src={imageNguyenVanDuy} alt="Anh Nguyễn Văn Duy" />
        ) : selectedPerson?.ten === "Anh Thành" ? (
          <img className="person-image" src={imageThanh} alt="Anh Thành" />
        ) : selectedPerson?.ten === "Anh Trần Quốc Đạt" ? (
          <img className="person-image" src={imageTranQuocDat} alt="Anh Trần Quốc Đạt" />
        ) : (
          <div></div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '15px', width: '100%' }}>
            <Button 
                variant="contained" 
                color="secondary" 
                className="mui-button"
                onClick={handleBack}
            >
                TRỞ LẠI
            </Button>
            <Button 
                variant="contained" 
                color="secondary" 
                className="mui-button"
                onClick={handleHome}
            >
                HOME
            </Button>
        </div>
      </Box>
    </div>
  );
}

export default CommentPerson;